import React, {useRef, useState} from 'react';
import {
	Box,
	Heading,
	HStack,
	Icon,
	IconButton,
	SlideFade,
	VStack,
	Text,
	Spacer,
	Portal,
	useBreakpointValue,
	Button,
	BoxProps,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {
	MdAccountBalanceWallet,
	MdCalendarToday,
	MdCheckCircle,
	MdClose,
	MdFreeCancellation,
	MdMarkChatRead,
	MdNotifications,
	MdOutlineNotifications,
} from 'react-icons/md';
import {Puddleglum} from '../puddleglum';
import {Notification} from '../types/Notification';

const NotificationBell = ({...boxProps}: BoxProps) => {
	const bellRef = useRef<HTMLDivElement>(null);

	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});

	const [showNotifications, setShowNotifications] = useState(false);

	const {data: notifications, refetch: refetchNotifications} = useQuery<Notification[]>(
		['notifications'],
		async () => {
			const {data} = await Puddleglum.Controllers.NotificationController.getNotifications();
			return data;
		},
	);

	const markAsRead = async (notification: Notification) => {
		await Puddleglum.Controllers.NotificationController.markAsRead(notification.id);

		refetchNotifications();
	};

	const markAllNotificationsAsRead = async () => {
		await Puddleglum.Controllers.NotificationController.markAllAsRead();
		refetchNotifications();
	};

	const hasUnreadNotifications = (notifications ?? []).some(
		(notification) => !notification.read_at,
	);

	const getIcon = (type: string) => {
		if (type.includes('AppointmentConfirmed'))
			return <Icon as={MdCheckCircle} boxSize={6} color='pink.500' />;
		if (type.includes('AppointmentCanceled'))
			return <Icon as={MdFreeCancellation} boxSize={6} color='pink.500' />;
		if (type.includes('AppointmentRequested'))
			return <Icon as={MdCalendarToday} boxSize={6} color='pink.500' />;
		if (type.includes('OverdueBalance'))
			return <Icon as={MdAccountBalanceWallet} boxSize={6} color='pink.500' />;

		return <Icon as={MdOutlineNotifications} boxSize={6} color='gray.500' />;
	};

	function getNotificationContents() {
		const boxPropsIfNotMobile = isMobile ? {} : boxProps;
		return (
			<Box
				zIndex='popover'
				position={{base: 'fixed', md: 'absolute'}}
				w={{base: '100%', md: '440px'}}
				mt={{base: 0, md: '70px'}}
				top={0} // This is the top of the screen
				left={!isMobile && bellRef.current ? bellRef.current.offsetLeft - 420 : 0}
				{...boxPropsIfNotMobile}
			>
				<SlideFade in={showNotifications} unmountOnExit>
					<Box
						w='auto'
						maxH={{base: '100vh', md: 'calc(100vh - 88px - 20px)'}}
						h={{base: '100vh', md: 'inherit'}}
						p={4}
						bgColor='white'
						borderRadius='lg'
						border='solid 1px'
						borderColor='gray.100'
						boxShadow='lg'
						overflowY='auto'
					>
						<HStack w='full' justifyContent='space-between'>
							<Heading size='md'>Notifications</Heading>
							<IconButton
								variant='ghost'
								aria-label='Close'
								icon={<Icon as={MdClose} />}
								onClick={() => setShowNotifications(false)}
							/>
						</HStack>

						{notifications?.length === 0 && (
							<Text color='gray.500' mt={4}>
								You have no notifications.
							</Text>
						)}

						<VStack spacing={4} mt={4}>
							{(notifications ?? []).map((notification) => (
								<HStack
									key={notification.id}
									w='full'
									bgColor={notification.read_at ? 'gray.50' : 'yellow.50'}
									p={4}
									borderRadius='md'
									border='solid 1px'
									borderColor={notification.read_at ? 'gray.100' : 'yellow.200'}
								>
									<Box w='32px'>{getIcon(notification.type)}</Box>
									<Box flex={1}>
										<HStack justifyContent='space-between' w='full'>
											<Heading size='sm'>
												{!notification.read_at && (
													<Box
														display='inline-block'
														bgColor='pink.500'
														borderRadius='full'
														w={2}
														h={2}
														mr={2}
													/>
												)}
												{notification.title}
											</Heading>
											<Text fontSize='xs'>{notification.created_at}</Text>
										</HStack>
										<HStack w='full'>
											<Text noOfLines={2} color='gray.500' fontSize='sm'>
												{notification.body}
											</Text>
											<Spacer />
											<HStack>
												{!notification.read_at && (
													<IconButton
														variant='ghost'
														size='sm'
														aria-label='Mark as read'
														icon={<Icon as={MdMarkChatRead} />}
														onClick={() => markAsRead(notification)}
													/>
												)}
											</HStack>
										</HStack>
									</Box>
								</HStack>
							))}
						</VStack>

						{hasUnreadNotifications && (
							<Button
								w='full'
								mt={4}
								colorScheme='teal'
								onClick={markAllNotificationsAsRead}
							>
								Mark all as read
							</Button>
						)}
					</Box>
				</SlideFade>
			</Box>
		);
	}

	return (
		<>
			<Box
				ref={bellRef}
				pos='relative'
				role='button'
				color={hasUnreadNotifications ? 'pink.500' : 'gray.300'}
				_hover={{color: 'pink.300'}}
				onClick={() => setShowNotifications(true)}
			>
				<Icon
					as={hasUnreadNotifications ? MdNotifications : MdOutlineNotifications}
					boxSize={6}
				/>
				{hasUnreadNotifications && (
					<Box
						pos='absolute'
						top={-1}
						right={-1}
						bgColor='pink.500'
						borderRadius='full'
						w={2}
						h={2}
					/>
				)}
			</Box>
			{isMobile ? <Portal>{getNotificationContents()}</Portal> : getNotificationContents()}
		</>
	);
};

export default NotificationBell;
